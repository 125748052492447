import React from "react";

const Blog = () => {
  return (
    <div>
      <div className="blog blog-2 pt-25 pb-50">
        <div className="container">
          <div className="panel panel-3">
            <div className="heading heading-2 text-center">
              <h2>Latest Blog</h2>
            </div>
            <div className="blog-slider blog-slider-2">
              <div className="single-blog">
                <div className="part-img">
                  <img src="assets/images/blog-img-4.jpg" alt="Image" />
                </div>
                <div className="part-txt">
                  <div className="tag-n-date">
                    <span>Shoping</span> <span>/</span>{" "}
                    <span>May 23, 2021</span>
                  </div>
                  <h3>
                    <a href="blog-details.html">
                      Commodo Sociosqu Venenatis Cras Dolor Sagittis Teger
                    </a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing eli sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis
                  </p>
                  <a href="blog-details.html" className="continue">
                    <span>
                      <i className="fa-light fa-arrow-right" />
                    </span>
                  </a>
                </div>
              </div>
              <div className="single-blog">
                <div className="part-img">
                  <img src="assets/images/blog-img-2.jpg" alt="Image" />
                </div>
                <div className="part-txt">
                  <div className="tag-n-date">
                    <span>Shoping</span> <span>/</span>{" "}
                    <span>May 23, 2021</span>
                  </div>
                  <h3>
                    <a href="blog-details.html">
                      Commodo Sociosqu Venenatis Cras Dolor Sagittis Teger
                    </a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing eli sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis
                  </p>
                  <a href="blog-details.html" className="continue">
                    <span>
                      <i className="fa-light fa-arrow-right" />
                    </span>
                  </a>
                </div>
              </div>
              <div className="single-blog">
                <div className="part-img">
                  <img src="assets/images/blog-img-5.jpg" alt="Image" />
                </div>
                <div className="part-txt">
                  <div className="tag-n-date">
                    <span>Shoping</span> <span>/</span>{" "}
                    <span>May 23, 2021</span>
                  </div>
                  <h3>
                    <a href="blog-details.html">
                      Commodo Sociosqu Venenatis Cras Dolor Sagittis Teger
                    </a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing eli sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis
                  </p>
                  <a href="blog-details.html" className="continue">
                    <span>
                      <i className="fa-light fa-arrow-right" />
                    </span>
                  </a>
                </div>
              </div>
              <div className="single-blog">
                <div className="part-img">
                  <img src="assets/images/blog-img-1.jpg" alt="Image" />
                </div>
                <div className="part-txt">
                  <div className="tag-n-date">
                    <span>Shoping</span> <span>/</span>{" "}
                    <span>May 23, 2021</span>
                  </div>
                  <h3>
                    <a href="blog-details.html">
                      Commodo Sociosqu Venenatis Cras Dolor Sagittis Teger
                    </a>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing eli sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis
                  </p>
                  <a href="blog-details.html" className="continue">
                    <span>
                      <i className="fa-light fa-arrow-right" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;

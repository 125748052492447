import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";

import { Link, useNavigate } from "react-router-dom";
import useToken from "../../hooks/useToken";
import toast from "react-hot-toast";
import "./Registration.css";
import { AuthContext } from "../../contexts/UserProvider";
const Registration = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [loginError, setLoginError] = useState("");
  const { registerUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const onSubmitRegister = async (data) => {
    const { firstName, email, phone, password, refferCode, photos } = data;

    await registerUser(firstName, email, phone, password, refferCode, photos);
    navigate("/");
  };
  // const onSubmitLogin = async (data) => {
  //   const { email, password } = data;

  //   await loginUser(email, password);
  //   navigate("/");
  // };

  // const onSubmitRegister = async (data) => {
  //   const { name, address, email, phone, password } = data;

  //   await registerUser(name, address, email, phone, password);
  //   navigate("/");
  // };
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  return (
    <div>
      <div className="Auth sign_in_all p-5">
        <div className="row ">
          <div className="col-md-6  p-lg-5 card">
            <div className="row ">
              <div className="col-md-6">
                <div className="log_img">
                  <img
                    src="/assets/images/5971191.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="registration_img">
                  <Link to={"/"}>
                    <img
                      src="https://i.ibb.co/P1H70ST/logo1.png"
                      alt="Logo"
                      className="registration_img_main"
                    />
                  </Link>
                </div>
                <form
                  className="infoForm authForm"
                  onSubmit={handleSubmit(onSubmitRegister)}
                >
                  <div className="form">
                    <input
                      type="text"
                      className="infoInput"
                      placeholder="Name"
                      {...register("name", {
                        required: "Name is Required",
                      })}
                    />
                    {errors.name && (
                      <p className="text-red-500">{errors.name.message}</p>
                    )}
                    <input
                      type="email"
                      className="infoInput"
                      placeholder="Username or Email"
                      {...register("email", {
                        required: true,
                      })}
                    />
                    {errors.email && (
                      <p className="text-red-500">{errors.email.message}</p>
                    )}
                    <input
                      type="password"
                      className="infoInput"
                      placeholder="Password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 6,
                          message: "Password must be 6 characters long",
                        },
                        pattern: {
                          value: /(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])/,
                          message:
                            "Password must have uppercase, number and special characters",
                        },
                      })}
                    />
                    {errors.password && (
                      <p className="text-red-500">{errors.password.message}</p>
                    )}
                  </div>

                  <button className="def-btn btn-border w-100">Register</button>
                </form>

                <div className="mt-5">
                  <p>
                    By continuing, you agree to Korbojoy's Conditions of Use and
                    Privacy Notice.
                  </p>
                  <div className="d-flex">
                    <p className="me-2">Already have an account? </p>
                    <Link to={"/login"}>Sign in</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
